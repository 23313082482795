<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<div class="k-logo" :class="sparkling?'':'k-logo__not-sparkling'">
		<!-- <span><img class="k-sparkl-logo-norton" src="/images/norton-logo.svg"></span> -->
		<span v-html="$store.state.site_config.logo_html"></span>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import '@/../../app-common/js/sparkler.js'
export default {
	components: {},
	props: {
	},
	data() { return {
		sparkling: false
	}},
	computed: {
		...mapState([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		toggle_sparkler(val) {
			// turn off the sparkler, at least for now
			return
			if (typeof(val) != 'boolean') val = !this.sparkling
			this.sparkling = val
			if (this.sparkling) {
				this.$nextTick(x=>{
					this.sparkler = new Sparkler()
				})
			}
		}
	}
}
</script>

<style lang="scss">
.k-logo__color1 { color: $v-blue-lighten-1; }
.k-logo__color2 { color: $v-purple-lighten-1; }
.k-logo__color6 { color: $v-orange-lighten-1; }	// A -- this is the sparkler in the top logo
.k-logo__color3 { color: $v-green-lighten-1; }
.k-logo__color4 { color: $v-red-lighten-1; }
.k-logo__color5 { color: $v-pink-lighten-2; }
.k-logo__color7 { color: $v-amber-darken-3; letter-spacing:2px!important; }
.k-logo__color8 { color: $v-cyan; }

.k-logo {
	overflow:visible!important;
	width:188px;
	margin-left:-6px;
	height:48px;
	border-radius:8px;
}

.k-logo-letter {
	position:relative;
	font-family: $display-font;
	font-size:36px;
	line-height:36px;
	// font-weight:900;		// use these for Rubik
	// letter-spacing:-1px;
}

.k-sparkl-logo-letter-holder {
	position:absolute;
	left:3px;
	top:8px;
}

#sparkler-stage {
	z-index:1;
	width:100px;
	height:100px;
	// border:2px solid red;
	position:absolute;
	top:-30px;
	left:26px;
}

.k-logo__not-sparkling {
	// #sparkler-stage {
	// 	left:50px;
	// }
	//
	// .k-logo__color1, .k-logo__color2, .k-logo__color3, .k-logo__color4, .k-logo__color5 {
	// 	visibility:hidden;
	// }
}

.k-sparkl-logo-ap {
	height:70px;
	margin-left:-4px;
	margin-top:-10px;
}

.k-sparkl-logo-norton {
	height:70px;
	margin-left:-4px;
	margin-top:-10px;
}

.k-velocity-logo {
	font-family: $display-font;
	font-size:28px;
	line-height:48px;
	background-color:transparent;
	border-radius:8px;
	// padding:0px 8px 14px 8px;
	margin:8px;
	text-shadow:-1.5px 1px 2px #555;

	.k-logo__color1, .k-logo__color2, .k-logo__color6, .k-logo__color3, .k-logo__color4, .k-logo__color5, .k-logo__color7, .k-logo__color8 { color: $v-indigo-lighten-2!important; }
}

.k-velocity-lc-logo {
	font-size:14px;
	line-height:0px;
	background-color:transparent;
	border-radius:8px;
	// padding:0px 8px 14px 8px;
	margin:4px 8px 0 12px;
	display:inline-block;
	text-align:center;
	
	.k-velocity-lc-logo-line1 {
		font-family: $display-font;
		text-shadow:-1.5px 1px 2px #555;
		font-size:24px;
		line-height:18px;
		color:$v-indigo-darken-2!important;
		display:inline-block;
		font-style:italic;
		margin-left:-4px;
	}

	.k-velocity-lc-logo-line2 {
		font-family: $sans-serif-font;
		text-shadow:0 0 0.5px #000;
		font-weight:900;
		font-size:18px;
		line-height:18px;
		color:$v-deep-orange-darken-4!important;
		padding-top:6px;
		display:inline-block;
	}
}

@media (max-width: 760px) {
	.k-sparkl-logo-letter-holder {
		left:-4px;
		top:-8px;
	}

	.k-logo-letter {
		font-size:28px;
	}

	.k-sparkl-logo-ap {
		height:40px;
	}
	.k-sparkl-logo-norton {
		height:40px;
	}

	.k-velocity-logo {
		font-size:22px;
		line-height:22px;
		margin:0px;
		text-shadow:-1.5px 1px 2px #555;
	}
}


///////////////////////////////
// default colors are for dark mode; set here for light mode
.theme--light {
	.k-logo-letter {
		// text-shadow:0px 0px 1px #000;
	}
	.k-logo__color1 { color: $v-blue-darken-2; }
	.k-logo__color2 { color: $v-purple-darken-2; }
	.k-logo__color6 { color: $v-orange-darken-3; }	// A -- this is the sparkler in the top logo
	.k-logo__color3 { color: $v-green-darken-2; }
	.k-logo__color4 { color: $v-red-darken-3; }
	.k-logo__color5 { color: $v-pink; }
	.k-logo__color7 { color: $v-amber-darken-4; }
	.k-logo__color8 { color: $v-cyan-darken-2; }

	.k-velocity-logo {
		text-shadow:-1.5px 1px 2px #999;
		.k-logo__color1, .k-logo__color2, .k-logo__color6, .k-logo__color3, .k-logo__color4, .k-logo__color5, .k-logo__color7, .k-logo__color8 { color: $v-indigo-darken-2!important; }
	}
}

</style>
